import React from 'react'
import * as styles from './TechList.module.css'
import TechStackTile from '../../tile/TechStackTile/TechStackTile'
import { useStaticQuery, graphql } from 'gatsby'

export default function TechList() {
    const data = useStaticQuery(graphql`
    query allTechs {
        mdx(slug: {eq: "techStack"}) {
            frontmatter {
              techStack {
                name,
                image {
                    childImageSharp {
                        gatsbyImageData(
                            width: 50
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                },
                key
              }
            }
          }
      }
    `);

    const techStack = data.mdx.frontmatter.techStack;

    return (
        <div className={styles.techList}>
        {   
            techStack.map((data)=>{
                return <TechStackTile 
                    name={data.name} 
                    imageData={data.image.childImageSharp}
                    key={data.key}
                />
            })
        }
    </div>
    )
}
