import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as styles from './TechStackTile.module.css'

export default function TechStackTile({name, imageData}) {

    const image = getImage(imageData)

    return (
        <div className={styles.techstack}>
            <div className={styles.icon}>
                <GatsbyImage image={image} alt=""/>
            </div>
            <p className={styles.text}>{name}</p>
        </div>
    )
}


